import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service'
 
import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class ValuelistService {
    
    private REST_API_SERVER = this.envService.apiUrl;
    private ROOT =  '/valuelist/';
    private TOKEN =  JSON.parse(localStorage.getItem('currentUser'));

    public STATUS = [
        { "name": 'Processing', "value": 'active', "variety": 'status' },
        { "name": 'Archived', "value": 'archived', "variety": 'status' },
        { "name": 'Cancelled', "value": 'cancelled', "variety": 'status' },
        { "name": 'Closed', "value": 'closed', "variety": 'status' },
    ]

    public STATES = [
        {"name":"Alabama","value":"AL"},
        {"name":"Alaska","value":"AK"},
        {"name":"Arizona","value":"AZ"},
        {"name":"Arkansas","value":"AR"},
        {"name":"California","value":"CA"},
        {"name":"Colorado","value":"CO"},
        {"name":"Connecticut","value":"CT"},
        {"name":"District Of Columbia","value":"DC"},
        {"name":"Delaware","value":"DE"},
        {"name":"Florida","value":"FL"},
        {"name":"Georgia","value":"GA"},
        {"name":"Hawaii","value":"HI"},
        {"name":"Idaho","value":"ID"},
        {"name":"Illinois","value":"IL"},
        {"name":"Indiana","value":"IN"},
        {"name":"Iowa","value":"IA"},
        {"name":"Kansas","value":"KS"},
        {"name":"Kentucky","value":"KY"},
        {"name":"Louisiana","value":"LA"},
        {"name":"Maine","value":"ME"},
        {"name":"Maryland","value":"MD"},
        {"name":"Massachusetts","value":"MA"},
        {"name":"Michigan","value":"MI"},
        {"name":"Minnesota","value":"MN"},
        {"name":"Mississippi","value":"MS"},
        {"name":"Missouri","value":"MO"},
        {"name":"Montana","value":"MT"},
        {"name":"Nebraska","value":"NE"},
        {"name":"Nevada","value":"NV"},
        {"name":"New Hampshire","value":"NH"},
        {"name":"New Jersey","value":"NJ"},
        {"name":"New Mexico","value":"NM"},
        {"name":"New York","value":"NY"},
        {"name":"North Carolina","value":"NC"},
        {"name":"North Dakota","value":"ND"},
        {"name":"Ohio","value":"OH"},
        {"name":"Oklahoma","value":"OK"},
        {"name":"Oregon","value":"OR"},
        {"name":"Pennsylvania","value":"PA"},
        {"name":"Rhode Island","value":"RI"},
        {"name":"South Carolina","value":"SC"},
        {"name":"South Dakota","value":"SD"},
        {"name":"Tennessee","value":"TN"},
        {"name":"Texas","value":"TX"},
        {"name":"Utah","value":"UT"},
        {"name":"Vermont","value":"VT"},
        {"name":"Virginia","value":"VA"},
        {"name":"Washington","value":"WA"},
        {"name":"West Virginia","value":"WV"},
        {"name":"Wisconsin","value":"WI"},
        {"name":"Wyoming","value":"WY"},
    ]

    constructor(
        private envService: EnvService,
        private http: HttpClient,
        private messageService: MessageService,
    ) { }

    add(data) {
        let headers = new HttpHeaders();
        data = JSON.stringify(data);
        return this.http.post(this.REST_API_SERVER + this.ROOT , data, {
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    delete(data) {
        let headers = new HttpHeaders();
        let vl = JSON.parse(data);
        vl['status']='archived';
        data = JSON.stringify(vl);
        return this.http.get(this.REST_API_SERVER + '/valuelist/' + data,{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    getAll() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + this.ROOT,{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    search(data) {
        let headers = new HttpHeaders();
        let search_string = ['?status=active'];
        if(data.hasOwnProperty('variety')) {
            search_string.push('&variety=' + data['variety']);
        }
        let search = search_string.join("");
        return this.http.get(this.REST_API_SERVER + '/vlsearch/' + search,{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }


    get(id) {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + this.ROOT + id,{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    getStatusName(subjects, type) {
        let statuses = [];
        let morestatuses = this.search({"variety": type})
        .subscribe({
            next: (result: any) => {
                for(let mstatus of result) {
                    statuses.push(mstatus);
                }
                for(let subject of subjects) {
                    for(let status of statuses) {
                        if(subject['status'] == status['value']) {
                            subject['status_name'] = status['name'];
                        }
                    }
                }

                return subjects
            },
            error: (err: any) => {
                if(err.statusText == 'Unknown Error') {
                    err.message = 'Server Not Responding';
                }
            }
        });
    }

    update(id, data) {
        let headers = new HttpHeaders();
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.put(this.REST_API_SERVER + this.ROOT + id + '/', data, {
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }
}   