import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class MenuService {
    constructor(public translate: TranslateService) {}

    getAll() {
        return [
            {
                link: "/",
                label: this.translate.instant("Home"),
                icon: "explore"
            },
            {
                label: this.translate.instant("Customer Service"),
                icon: "support_agent",
                items: [
                    { link: "/order/list", icon: "shopping_cart", label: this.translate.instant("List Orders") },
                    { link: "/order/flagged", icon: "shopping_cart", label: this.translate.instant("Flagged Orders") },
                    { link: "/order/archived", icon: "shopping_cart", label: this.translate.instant("Archived Orders") },
                ]
            },
            {
                label: this.translate.instant("Fulfillments"),
                icon: "flight",
                items: [
                    { link: "/report/shipping", icon: "list", label: this.translate.instant("Shipping") },
                    { link: "/report/picklist", icon: "list", label: this.translate.instant("Picklist") },
                ]
            },
            {
                label: this.translate.instant("Products"),
                icon: "list",
                items: [
                    { link: "/product/list", icon: "list", label: this.translate.instant("List") },
                    { link: "/product/add", icon: "add", label: this.translate.instant("Add") },
                ]
            },
            {
                label: "Finance Reports",
                icon: "price_check",
                items: [
                    { link: "/finance/daily", icon: "inventory", label: "Ordered" },
                    { link: "/finance/shipped", icon: "flight", label: "Shipped" },
                    { link: "/finance/inhouse", icon: "apartment", label: "Inhouse" },
                    { link: "/finance/giftcard", icon: "card_giftcard", label: "Giftcard" },
                    { link: "/finance/unearned", icon: "money_off", label: "Unearned" },
                ]
            },
            
            /*
            {
                label: this.translate.instant("Customer"),
                icon: "face",
                items: [
                    { link: "/customer/list", icon: "person", label: this.translate.instant("List") },
                    { link: "/customer/add", icon: "person_add", label: this.translate.instant("Add") },
                ]
            },          
            {
                label: this.translate.instant("Locations"),
                icon: "place",
                items: [
                    { link: "/location/list", icon: "place", label: this.translate.instant("List") },
                    { link: "/location/add", icon: "add_location", label: this.translate.instant("Add") },
                ]
            }, 
            {
                link: "/users/list",
                label: this.translate.instant("Directory"),
                icon: "people",
                items: [
                    { link: "/users/list", icon: "place", label: this.translate.instant("List") },
                    { link: "users/auth-list", icon: "add_location", label: this.translate.instant("Auhorized Users") },
                ]
            },
            */         
            /*
            {
                link: "/apps/calendar",
                label: this.translate.instant("Calendar"),
                icon: "calendar_view_month",
            },
            {
                link: "/taskboard",
                label: this.translate.instant("Tasks"),
                icon: "task_alt"
            },
            {
                link: "/knowledge",
                label: this.translate.instant("Knowledge Base"),
                icon: "school"
            },
            */
            {
                label: this.translate.instant("Administration"),
                icon: "room_preferences",
                items: [
                    { link: "/administration/orderstatuslist", icon: "build", label: this.translate.instant("Order Status Type") },
                ]
            },
        ];
    }
}
