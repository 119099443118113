import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service'

import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class ComponentService {

    private REST_API_SERVER = this.envService.apiUrl;
    private TOKEN = JSON.parse(localStorage.getItem('currentUser'));

    COMPONENTS = [
        {
            "id": 1,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Output Gear",
            "partNum": "70358-06603-102",
            "score": 0,
            "subsystem": "TGB",
            "image": "tgb_output_gear"
        },
        {
            "id": 2,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Output Gear",
            "partNum": "70357-06315-101",
            "score": 0,
            "subsystem": "IGB",
            "image": "igb_output_gear"
        },
        {
            "id": 3,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Output Gear",
            "partNum": "12-0345-M",
            "score": 0,
            "subsystem": "MGB",
            "image": "mgb_output_gear"
        },
        {
            "id": 4,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Output Assembly Output Bearing",
            "partNum": "SB-3314",
            "score": 0,
            "subsystem": "TGB",
            "image": "tgb_output_assy_output_bearing"
        },
        {
            "id": 5,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Output Assembly Input Bearing",
            "partNum": "SB-3353",
            "score": 0,
            "subsystem": "TGB",
            "image": "tgb_output_assy_input_bearing"
        },
        {
            "id": 6,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Output Assembly Output Bearing",
            "partNum": "SB-3217",
            "score": 0,
            "subsystem": "IGB",
            "image": "igb_output_assy_output_bearing"
        },
        {
            "id": 7,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Output Assembly Input Bearing",
            "partNum": "SB-3265",
            "score": 0,
            "subsystem": "IGB",
            "image": "igb_output_assy_input_bearing"
        },
        {
            "id": 8,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Output Bearing",
            "partNum": "12-0345-M",
            "score": 0,
            "subsystem": "MGB",
            "image": "mgb_output_bearing"
        },
        {
            "id": 9,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Input Pinion",
            "partNum": "70358-06619-101",
            "score": 0,
            "subsystem": "TGB",
            "image": "tgb_input_pinion"
        },
        {
            "id": 10,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Input Pinion",
            "partNum": "70357-06314-101",
            "score": 0,
            "subsystem": "IGB",
            "image": "igb_input_pinion"
        },
        {
            "id": 11,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Input Pinion",
            "partNum": "12-0345-M",
            "score": 0,
            "subsystem": "MGB",
            "image": "mgb_input_pinion"
        },
        {
            "id": 12,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Input Assembly Output Bearing",
            "partNum": "SB-3266",
            "score": 0,
            "subsystem": "TGB",
            "image": "tgb_input_assy_output_bearing"
        },
        {
            "id": 13,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Input Assembly Input Bearing",
            "partNum": "SB-3217",
            "score": 0,
            "subsystem": "TGB",
            "image": "tgb_input_assy_input_bearing"
        },
        {
            "id": 14,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Input Assembly Output Bearing",
            "partNum": "SB-3265",
            "score": 0,
            "subsystem": "IGB",
            "image": "igb_input_assy_output_bearing"
        },
        {
            "id": 15,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Input Assembly Input Bearing",
            "partNum": "SB-3217",
            "score": 0,
            "subsystem": "IGB",
            "image": "igb_input_assy_input_bearing"
        },
        {
            "id": 16,
            "failure_probability": 0,
            "flightTime": 0,
            "name": "Input Bearing",
            "partNum": "12-0345-M",
            "score": 0,
            "subsystem": "MGB",
            "image": "mgb_input_bearing"
        }
    ]

    constructor(
        private envService: EnvService,
        private http: HttpClient,
        private messageService: MessageService,
    ) {
    }

    getHardAll() {
        return this.COMPONENTS
    }

    getComponentById(componentId: Number) {
        let component = this.COMPONENTS.filter(x => x.id == componentId)
        return component[0]
    }

    // Auto-generated functions below this line.

    add(data) {
        let headers = new HttpHeaders();
        data = JSON.parse(data);
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.post(this.REST_API_SERVER + '/order/', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getAll() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/order/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getArchived() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/archived/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getHeatMap(color) {
        if (color < 80) {
            return 'red';
        } else if (color >= 80 && color < 90) {
            return 'yellow';
        } else if(color >= 90) {
            return '#71F4DC';
        }
    }

    getFlagged() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/flagged/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getMinimumComponent(aircraft) {
        let COMPS = [1,5]
        let SCORES = []
        let COMPONENTS= []
        for(let system of aircraft.systems) {
            for(let subsystem of system.subsystems) {
                for(let component of subsystem.components) {
                    if(COMPS.indexOf(component.id) > -1) {
                        SCORES.push(component.score);
                        COMPONENTS.push(component);
                    }
                }
            }
        }
        let the_color = Math.min(...SCORES)
        return [the_color,COMPONENTS]
    }

    getSearch(data) {
        // Create headers
        let headers = new HttpHeaders();
        let search_string = ['?1'];
        if (data.hasOwnProperty('status')) {
            if (data['status'] == 'all') {
                //
            } else {
                search_string.push('&status=' + data['status']);
            }
        } else {
            search_string.push('&status=active');
        }
        if (data.hasOwnProperty('date')) {
            search_string.push('&date=' + data['date']);
        }
        if (data.hasOwnProperty('dateEnd')) {
            search_string.push('&date_end=' + data['dateEnd']);
        }
        if (data.hasOwnProperty('dateOrder')) {
            search_string.push('&date_order=' + data['dateOrder']);
        }
        if (data.hasOwnProperty('dateStart')) {
            search_string.push('&date_start=' + data['dateStart']);
        }
        if (data.hasOwnProperty('mln')) {
            search_string.push('&mln=' + data['mln']);
        }
        if (data.hasOwnProperty('product')) {
            search_string.push('&product=' + data['product']);
        }
        // TYPE
        if (data.hasOwnProperty('type')) {
            search_string.push('&type=' + data['type']);
        }
        let search = search_string.join("");
        console.log(search)
        return this.http.get(this.REST_API_SERVER + '/shipping/' + search, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    get(id) {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/order/' + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    partial_update(data) {
        let headers = new HttpHeaders();
        let id = data['id'];
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.patch(this.REST_API_SERVER + '/order_partial/' + id + '/', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    update(id, data) {
        let headers = new HttpHeaders();
        data = JSON.parse(data);
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.put(this.REST_API_SERVER + '/order/' + id + '/', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    sortActions(orders) {
        /*
            This will sort all of the actions returned and make them accessible for icon display etc
        */
        console.log(JSON.stringify(orders))
        if (orders.hasOwnProperty('addresses')) {
            orders.printed = 0;
            for (let action of orders.actions) {
                if (action.variety == 'print') {
                    orders.printed = orders.printed + 1;
                }
            }
        } else {
            for (let order of orders) {
                order.printed = 0;
                for (let action of order.actions) {
                    if (action.variety == 'print') {
                        order.printed = order.printed + 1;
                    }
                }
            }
        }

        return orders
    }
}   