import { Component, OnInit } from '@angular/core';
import { VehicleService } from '../_services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  fleet = []

  constructor(
      private vehicleService: VehicleService,
  ) { }


  ngOnInit() { 
      this.fleet = this.vehicleService.getHardAll();
  }
}
