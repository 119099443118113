import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service'
import _ from 'lodash';

import { MissionService } from './mission.service';

@Injectable({ providedIn: 'root' })
export class MixService {

    MIXES = [
        {
            'id': 1,
            'name': 'Mission Mix A',
            'missions': [
                {
                    'id': 1,
                    'name': 'Troop Transport',
                },
                {
                    'id': 2,
                    'name': 'Rapid Resupply',
                },
                {
                    'id': 1,
                    'name': 'Troop Transport',
                },
                {
                    'id': 2,
                    'name': 'Rapid Resupply',
                },
                {
                    'id': 2,
                    'name': 'Rapid Resupply',
                }
            ]
        },
        {
            'id': 2,
            'name': 'Mission Mix B',
            'missions': [
                {
                    'id': 1,
                    'name': 'Troop Transport',
                },
                {
                    'id': 2,
                    'name': 'Rapid Resupply',
                },
                {
                    'id': 2,
                    'name': 'Rapid Resupply',
                }
            ]
        },
        {
            'id': 3,
            'name': 'Mission Mix C',
            'missions': [
                {
                    'id': 1,
                    'name': 'Troop Transport',
                },
                {
                    'id': 2,
                    'name': 'Rapid Resupply',
                },
                {
                    'id': 3,
                    'name': 'S7 Air Assault (TAA-PZ-LZ-TAA)',
                }
            ]
        },
    ]


    private REST_API_SERVER = this.envService.apiUrl;
    private TOKEN = JSON.parse(localStorage.getItem('currentUser'));

    currentMix: any;

    constructor(
        private envService: EnvService,
        private http: HttpClient,
        private missionService: MissionService,
    ) { }

    setMix(id) {
        this.currentMix = id;
    }

    getByMix(id) {
        let mixes = [];
        for (let mix of this.MIXES) {
            if (mix.id == id) {
                mixes.push(mix);
            }
        }
        return mixes[0]
    }

    getCurrentMix() {
        for (let mission of this.MIXES) {
            if (mission.id == this.currentMix) {
                return mission
            }
        }
    }

    getHardAll() {
        return this.MIXES
    }

    getMissionsById(mixId: Number) {
        let mix = this.MIXES.filter(x => x.id == mixId)
        return mix[0]['missions']
    }

    getMixById(mixId: Number) {
        let mix = this.MIXES.filter(x => x.id == mixId)
        return mix[0]
    }

    getByFleet(id) {
        let mixes = [];
        for (let mix of this.MIXES) {
            if (mix.id == id) {
                mixes.push(mix);
            }
        }
        return mixes
    }

    // Return cumulative wear rate information broken down by component across all missions.
    // Return data follows this structure: 
    // [
    //     {
    //         "id": "1",
    //         "name": "Rear Primary Gear",
    //         "score": 0.054000000000000006
    //     },
    //     {
    //         "id": "2",
    //         "name": "Intermediate Primary Gear",
    //         "score": 0.504
    //     },
    //     {
    //         "id": "3",
    //         "name": "Main Primary Gear",
    //         "score": 0.077
    //     },
    //     {
    //         "id": "4",
    //         "name": "Landing Gear",
    //         "score": 0.9800000000000001
    //     },
    //     {
    //         "id": "5",
    //         "name": "Rear Rotor Bearing",
    //         "score": 0.066
    //     },
    //     {
    //         "id": "6",
    //         "name": "Primary Rotor Bearing",
    //         "score": 0.012
    //     }
    // ]

    getTotalWearRate(mixId: Number) {
        let componentNameDictionary = {};
        let missionIds = this.getMissionsById(mixId).map((x) => x.id)
        let missionInfo = [];
        let wearAggregator = {};
        let wearRates = [];

        for (let id of missionIds) {
            missionInfo.push(this.missionService.getWearRateById(id))
        }
        let tempComponentInfo = _.flattenDeep(missionInfo)
        for (let component of tempComponentInfo) {
            if (!wearAggregator[component.id]) {
                wearAggregator[component.id] = 0
            }
            // Compile a dictionary of component names to aid composition of return value later.
            if (!componentNameDictionary[component.id]) {
                componentNameDictionary[component.id] = component.name
            }
            wearAggregator[component.id] += component.score
        }
        for (let key of Object.keys(wearAggregator)) {
            wearRates.push({
                id: key,
                name: componentNameDictionary[key],
                score: wearAggregator[key]
            })
        }
        return wearRates;
    }

    getFlightTimes(mixId: Number) {
       let mix = this.getMixById(mixId);
       let totalMissionTime = 0;
       mix.missions.forEach((mission) => {
           totalMissionTime += (this.missionService.getTotalFlightHours(mission.id, true));
       })
    //    let hours = Math.floor(totalMissionTime / 60);
    //    let minutes = Math.floor(totalMissionTime % 60);

       return (totalMissionTime / 60)
    }
}   