import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service'
 
import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

    CONFIGURATIONS = [
        {                
            'id': 1,
            'name': 'UH-60A Black Hawk',
        },
        {                
            'id': 1,
            'name': 'UH-60C Black Hawk',
        },
        {                
            'id': 1,
            'name': 'UH-60L Black Hawk',
        },
        {                
            'id': 1,
            'name': 'UH-60M Black Hawk',
        },
        {                
            'id': 1,
            'name': 'UH-60M Upgrade Black Hawk',
        },
        {                
            'id': 1,
            'name': 'UH-60V Black Hawk',
        },
    ]

    private REST_API_SERVER = this.envService.apiUrl;
    private TOKEN =  JSON.parse(localStorage.getItem('currentUser'));
    currentConfiguration: number
    ;
    constructor(
        private envService: EnvService,
        private http: HttpClient,
        private messageService: MessageService,
    )   { }

    setConfiguration(id) {
        this.currentConfiguration = id;
    }

    getCurrentConfiguration() {
        for(let configuration of this.CONFIGURATIONS) {
            if(configuration.id == this.currentConfiguration) {
                return configuration
            }
        }
    }

    getConfiguration(id) {

    }

    getHardAll() {               
        return this.CONFIGURATIONS
    }

    add(data) {
        let headers = new HttpHeaders();
        data = JSON.parse(data);
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.post(this.REST_API_SERVER + '/order/', data, {
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    getAll() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/order/',{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    getArchived() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/archived/',{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    getFlagged() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/flagged/',{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    getSearch(data) {
        // Create headers
        let headers = new HttpHeaders();
        let search_string = ['?1'];
        if(data.hasOwnProperty('status')) {
            if(data['status']=='all') {
                //
            } else {
                search_string.push('&status=' + data['status']);
            }
        } else {
            search_string.push('&status=active');
        }
        if(data.hasOwnProperty('date')) {
            search_string.push('&date=' + data['date']);
        }
        if(data.hasOwnProperty('dateEnd')) {
            search_string.push('&date_end=' + data['dateEnd']);
        }
        if(data.hasOwnProperty('dateOrder')) {
            search_string.push('&date_order=' + data['dateOrder']);
        }
        if(data.hasOwnProperty('dateStart')) {
            search_string.push('&date_start=' + data['dateStart']);
        }
        if(data.hasOwnProperty('mln')) {
            search_string.push('&mln=' + data['mln']);
        }
        if(data.hasOwnProperty('product')) {
            search_string.push('&product=' + data['product']);
        }
        // TYPE
        if(data.hasOwnProperty('type')) {
            search_string.push('&type=' + data['type']);
        }
        let search = search_string.join("");
        console.log(search)
        return this.http.get(this.REST_API_SERVER + '/shipping/' + search,{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    get(id) {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/order/' + id,{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    partial_update(data) {
        let headers = new HttpHeaders();
        let id = data['id'];
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.patch(this.REST_API_SERVER + '/order_partial/' + id + '/', data, {
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    update(id, data) {
        let headers = new HttpHeaders();
        data = JSON.parse(data);
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.put(this.REST_API_SERVER + '/order/' + id + '/', data, {
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
    }

    sortActions(orders) {
        /*
            This will sort all of the actions returned and make them accessible for icon display etc
        */
       console.log(JSON.stringify(orders))
        if(orders.hasOwnProperty('addresses')) {
            orders.printed = 0;
            for(let action of orders.actions) {
                if(action.variety == 'print') {
                    orders.printed = orders.printed + 1;
                }
            }
        } else {
            for(let order of orders) {
                order.printed = 0;
                for(let action of order.actions) {
                    if(action.variety == 'print') {
                        order.printed = order.printed + 1;
                    }
                }
            }
        }

        return orders
    }
}   