import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service'
import { SegmentService } from './segment.service';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class MissionService {

    MISSIONS = [
        {
            'id': 1,
            'name': 'Troop Transport',
            'segments': [
                {
                    'id': 1,
                    'name': 'Takeoff'
                },
                {
                    'id': 3,
                    'name': 'H.A. Cruise'
                },
                {
                    'id': 2,
                    'name': 'Landing'
                },
                {
                    'id': 1,
                    'name': 'Takeoff'
                },
                {
                    'id': 3,
                    'name': 'H.A. Cruise'
                },
                {
                    'id': 2,
                    'name': 'Landing'
                }
            ]
        },
        {
            'id': 2,
            'name': 'Rapid Resupply',
            'segments': [
                {
                    'id': 1,
                    'name': 'Takeoff'
                },
                {
                    'id': 4,
                    'name': 'Load'
                },
                {
                    'id': 3,
                    'name': 'H.A. Cruise',
                },
                {
                    'id': 5,
                    'name': 'Unload'
                },
                {
                    'id': 2,
                    'name': 'Landing'
                }
            ]
        },
        {
            'id': 3,
            'name': 'S7 Air Assault (TAA-PZ-LZ-TAA)',
            'segments': [{
                "id": 7,
                "name": "Cruise at 225 KTAS (1)",
            },
            {
                "id": 8,
                "name": "Descend to land PZ",
            },
            {
                "id": 9,
                "name": "PZ Load (ground idle)",
            },
            {
                "id": 10,
                "name": "T/O PZ to 80’ AHO",
            },
            {
                "id": 11,
                "name": "Cruise at 225 KTAS (2)",
            },
            {
                "id": 12,
                "name": "Descend to land LZ",
            },
            {
                "id": 13,
                "name": "Unload at LZ (ground idle)",
            },
            {
                "id": 14,
                "name": "T/O LZ to 80’ AHO",
            },
            {
                "id": 15,
                "name": "Cruise at VBR (1)",
            },
            {
                "id": 16,
                "name": "Climb vic PZ to 200’ AHO 1",
            },
            {
                "id": 17,
                "name": "Cruise at VBR (2)",
            },
            {
                "id": 18,
                "name": "Descend to land TAA (no hover)",
            }]
        },
    ]

    private REST_API_SERVER = this.envService.apiUrl;
    private TOKEN = JSON.parse(localStorage.getItem('currentUser'));
    currentMission: number;

    constructor(
        private envService: EnvService,
        private http: HttpClient,
        private segmentService: SegmentService,
    ) { }

    getByMission(id) {
        let missions = [];
        for (let mission of this.MISSIONS) {
            if (mission.id == id) {
                missions.push(mission);
            }
        }
        return missions[0]
    }

    getCurrentMission() {
        for (let mission of this.MISSIONS) {
            if (mission.id == this.currentMission) {
                return mission
            }
        }
    }

    getHardAll() {
        return this.MISSIONS
    }

    getSegmentsById(missionId: Number) {
        let mission = this.MISSIONS.filter(x => x.id === missionId)[0]
        let missionSegments = [];
        // Get full mission segments from segment-service.
        for (let segment of mission.segments) {
            missionSegments.push(this.segmentService.getBySegment(segment.id))
        }
        return missionSegments
    }

    getTotalFlightHours(missionId: Number, asMinutes: Boolean = false) {
        let flightTimes = this.getSegmentsById(missionId).map(x => x.flightTime)
        let totalFlightTime = flightTimes.reduceRight((accumulator, currentValue) => {
            return accumulator + currentValue;
        })
        let hours = Math.floor(totalFlightTime / 60);
        let minutes = Math.floor((totalFlightTime % 60));
        return (asMinutes ? totalFlightTime : {hours, minutes})
    }

    // Return wear rate information broken down by component across all mission segments.
    // Return data looks like this: 
    // [
    //     {
    //         "id": "1",
    //         "name": "Rear Primary Gear",
    //         "score": 0.012
    //     },
    //     {
    //         "id": "2",
    //         "name": "Intermediate Primary Gear",
    //         "score": 0.14400000000000002
    //     },
    //     {
    //         "id": "3",
    //         "name": "Main Primary Gear",
    //         "score": 0.022
    //     },
    //     {
    //         "id": "4",
    //         "name": "Landing Gear",
    //         "score": 0.28
    //     }
    // ]

    getWearRateById(missionId: Number) {
        let aggregateWearRates = []
        let componentNameDictionary = {}

        let segments = this.getSegmentsById(missionId)
        let segmentComponents = segments.map((x) => x.components)
        let flatComponents = _.flattenDeep(segmentComponents)
        let wearAggregator = {};
        // Add up the wear rates from all components.
        for (let component of flatComponents) {
            if (!wearAggregator[component.id]) {
                wearAggregator[component.id] = 0
            }
            // Compile a dictionary of component names to aid composition of return value later.
            if (!componentNameDictionary[component.id]) {
                componentNameDictionary[component.id] = component.name
            }
            wearAggregator[component.id] += component.score
        }
        // Compose return value.
        for (let key of Object.keys(wearAggregator)) {
            aggregateWearRates.push({
                id: key,
                name: componentNameDictionary[key],
                score: wearAggregator[key]
            })
        }
        return aggregateWearRates
    }

    setMission(id) {
        this.currentMission = id;
    }
}   