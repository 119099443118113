import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LayerComponentComponent } from './component/layer-component.component'

const routes: Routes = []

@NgModule({
    exports: [
        LayerComponentComponent,
    ],
    declarations: [
        LayerComponentComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})

export class LayersModule { }
