import { Injectable } from "@angular/core";

export enum Environment {
    Prod = 'prod',
    Staging = 'staging',
  }
  
  @Injectable({ providedIn: 'root' })
  export class EnvService {
    private _env: Environment;
    private _apiUrl: string;
  
    get env(): Environment {
      return this._env;
    }
  
    get apiUrl(): string {
      return this._apiUrl;
    }
  
    constructor() {}
  
    init(): Promise<void> {
      return new Promise(resolve => {
        this.setEnvVariables();
        resolve();
      });
    }

    ripplingUrl = '';
    ripplingToken = ''
  
    private setEnvVariables(): void {
      const hostname = window && window.location && window.location.hostname;

      this.ripplingUrl = 'https://api.rippling.com/platform/api',
      this.ripplingToken = 'FQFZwrUbUFmIHjrR2Qk2PDtPhnGvuMFAqybyGV9VuAb6cjTosXKQGMbXjvdvQcwA'  
  
      if (/^44.196.220.106/.test(hostname)) {
        this._env = Environment.Staging;
        this._apiUrl = 'http://3.226.231.185:8000';
      } else if (/^klendanet.com/.test(hostname)) {
        this._env = Environment.Prod;
        this._apiUrl = 'http://54.156.23.113:8000';
      } else {
        console.warn(`Cannot find environment for host name ${hostname}`);
      }
    }
  }