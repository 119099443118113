import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service'

@Injectable({ providedIn: 'root' })
export class UserService {
    /*
        API Calls to retrieve Rippling and K-NET users
        AUTH = K-NET
        USER = Rippling
        
        TODO: Refactor the calls to DRY
    */

    private REST_API_SERVER = this.envService.apiUrl;
    private TOKEN = JSON.parse(localStorage.getItem('currentUser'));
    private RIPPLING = this.envService.ripplingUrl;
    private RIPPLINGTOKEN = this.envService.ripplingToken;

    USERS = [
        {
            "id": 1,
            "name": "Commander Demo",
            "email": "comm@sentientdemo.com",
            "password": "fvldemo",
            "role": "commander"
        },
        {
            "id": 2,
            "name": "Engineer Demo",
            "email": "engi@sentientdemo.com",
            "password": "fvldemo",
            "role": "engineer"
        },
        {
            "id": 3,
            "name": "Maintainer Demo",
            "email": "logi@sentientdemo.com",
            "password": "fvldemo",
            "role": "maintainer"
        },
        {
            "id": 4,
            "name": "Developer Demo",
            "email": "developer@sentientdemo.com",
            "password": "fvldemo",
            "role": "developer"
        }
    ]

    constructor(
        private envService: EnvService,
        private http: HttpClient
    ) { }


    login(email: string, password: string) {
        let user = this.USERS.filter(x => x.email === email.toLowerCase())[0]
        if (user && password.toLowerCase() === user.password) {
            sessionStorage.setItem('username', user.name);
            sessionStorage.setItem('role', user.role);
            return true
        } else {
            return false
        };
    }

    forceLogin(role: string) {
        let user = this.USERS.filter(x => x.role === role.toLowerCase())[0]
        if (user && user.role) {
            sessionStorage.setItem('username', user.name);
            sessionStorage.setItem('role', user.role);
            return true
        } else {
            return false
        };

    }


    addAuth(data) {
        let headers = new HttpHeaders();
        return this.http.post(this.REST_API_SERVER + '/register/', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    getAll() {
        let headers = new HttpHeaders();
        return this.http.get(this.RIPPLING + '/employees', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.RIPPLINGTOKEN,
            }
        });
    }

    getAllAuth() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/auths/', {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    }

    getAllAuthd() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/user/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getAuth(id) {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/user/' + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getUser(id) {
        let headers = new HttpHeaders();
        return this.http.get(this.RIPPLING + '/employees/' + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.RIPPLINGTOKEN,
            }
        });
    }

    getAllTerminated() {
        let headers = new HttpHeaders();
        return this.http.get(this.RIPPLING + '/employees/include_terminated', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.RIPPLINGTOKEN,
            }
        });
    }

    updateAuth(id, data) {
        let headers = new HttpHeaders();
        return this.http.put(this.REST_API_SERVER + '/user/' + id + '/', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }
}   