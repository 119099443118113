import { Injectable } from '@angular/core';
import { EnvService } from './env.service'

@Injectable({ providedIn: 'root' })
export class SettingsService {

    SETTINGS = {
        bands: {
            1: 100,
            2: 90,
            3: 80,
        },
        degradationRates: {
            TGB: {
                Low: {
                    50: 0.999,
                    100: 0.99,
                    150: 0.98
                },
                High: {
                    50: 0.94,
                    100: 0.93,
                    150: 0.92
                },
            },
            IGB: {
                Low: {
                    50: 0.9995,
                    100: 0.995,
                    150: 0.985
                },
                High: {
                    50: 0.95,
                    100: 0.94,
                    150: 0.93
                }
            },
            minimum: 0.999
        }
    }
    constructor(
        private envService: EnvService,
    ) { }

    getSetting(name) {
        return this.SETTINGS[`${name}`]
    }

    getHardAll() {
        return this.SETTINGS
    }
}  