/* export.service.ts */
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

const CSV_EXTENSION = '.csv';

@Injectable({ providedIn: 'root' })
export class ExportService {
    constructor() { }

    /**
         * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
         *
         * @param rows array of data to be converted to CSV.
         * @param fileName filename to save as.
         * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
     */
    exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
        rows.shift();
        for(let row of rows) {
            console.log(JSON.stringify(row));
            for(let column of columns) {
                console.log(row['order_id'] + " : " + row[column]);
            }
        }
        if (!rows || !rows.length) {
            return;
        } else {
            console.log("ROWS: " + rows.length);
        }
        const separator = ',';
        const keys = Object.keys(rows[0]).filter(k => {
            if (columns.length) {
                console.log("COLUMNS: ", columns.length);
                return columns.includes(k);
            } else {
                return true;
            }
        });
        const csvContent =
            keys.join(separator) +
            '\n' +
            rows.map(row => {
                return keys.map(k => {
                    let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                    cell = cell instanceof Date
                        ? cell
                        : cell.toString().replace(/"/g, '""');
                    if (cell.search(/("|,|\n)/g) >= 0) {
                        cell = `"${cell}"`;
                    }
                    return cell;
                }).join(separator);
            }).join('\n');
        this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`);
    }

    /**
         * Saves the file on the client's machine via FileSaver library.
         *
         * @param buffer The data that need to be saved.
         * @param fileName File name to save as.
         * @param fileType File type to save as.
     */
    saveAsFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: 'text/csv' });
        FileSaver.saveAs(data, fileName);
    }
}