import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

import { VehicleService } from '../../_services'

import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;

  elem: any;
  SEARCH: string;
  loggedInUser: string;
  userRole: string;
  constructor(
    @Inject(DOCUMENT) private document: any,
    config: NgbDropdownConfig,
    private router: Router,
    private vehicleService: VehicleService,
  ) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.toggleSidebar()
    // this.openFullscreen()
    this.loggedInUser = sessionStorage.getItem('username')
    this.userRole = sessionStorage.getItem('role')
   }

   logout() {
     sessionStorage.clear();
     this.router.navigate(['/user/login'])
   }

  findVehicle() {
      let found = this.vehicleService.get(this.SEARCH);
      if(found != 'ERROR') {
          this.vehicleService.setVehicle(this.SEARCH);
          this.router.navigate(['/aircraft/detail']);
          this.SEARCH = '';
      } else {
          this.SEARCH = found;
      }
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

    /*
    fullScreenToggle(): void {
        if (screenfull.isEnabled) {
            screenfull.toggle();
        }
    }
    */
  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  toggleRightSidebar() {
    document.querySelector('#right-sidebar').classList.toggle('open');
  }

}
