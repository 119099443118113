import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service'

import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class VehicleService {

    VEHICLES = [
        {
            "id": 1,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 14198400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "10-71121",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5110,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 90.11972972850438,
                                    "subsystem": "TGB",
                                    "serial": 43869,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 94.9,
                                    "subsystem": "TGB",
                                    "serial": 36985,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92.08832072371949,
                                    "subsystem": "TGB",
                                    "serial": 39615,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 96.74,
                                    "subsystem": "TGB",
                                    "serial": 48302,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 94.83,
                                    "subsystem": "TGB",
                                    "serial": 46473,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.64,
                                    "subsystem": "TGB",
                                    "serial": 98457,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 12552000,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 1279,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 99.31,
                                    "subsystem": "IGB",
                                    "serial": 74800,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.74,
                                    "subsystem": "IGB",
                                    "serial": 82160,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.18,
                                    "subsystem": "IGB",
                                    "serial": 13793,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 93.24,
                                    "subsystem": "IGB",
                                    "serial": 82304,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.11,
                                    "subsystem": "IGB",
                                    "serial": 87643,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.85,
                                    "subsystem": "IGB",
                                    "serial": 41790,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 12552000,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 1164,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 63226,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 54414,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 50375,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 12552000,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 24316,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 12552000,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 2,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 14788800,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "11-18454",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 6389,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 92.37,
                                    "subsystem": "TGB",
                                    "serial": 32457,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 94.31,
                                    "subsystem": "TGB",
                                    "serial": 47628,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 93.81,
                                    "subsystem": "TGB",
                                    "serial": 76398,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 93.34,
                                    "subsystem": "TGB",
                                    "serial": 61513,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 97.78,
                                    "subsystem": "TGB",
                                    "serial": 19135,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.09,
                                    "subsystem": "TGB",
                                    "serial": 77925,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 19536000,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 7919,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 92.25,
                                    "subsystem": "IGB",
                                    "serial": 76176,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.92,
                                    "subsystem": "IGB",
                                    "serial": 50141,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.19,
                                    "subsystem": "IGB",
                                    "serial": 59108,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 90.46,
                                    "subsystem": "IGB",
                                    "serial": 26431,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 93.1,
                                    "subsystem": "IGB",
                                    "serial": 54778,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.51,
                                    "subsystem": "IGB",
                                    "serial": 14468,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 19536000,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 2156,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 77757,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 70235,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 87983,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 19536000,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 94085,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 19536000,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 3,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 17013600,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "11-26043",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 1073,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 93.26,
                                    "subsystem": "TGB",
                                    "serial": 36248,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 92.64,
                                    "subsystem": "TGB",
                                    "serial": 58427,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 90.84,
                                    "subsystem": "TGB",
                                    "serial": 64767,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 96.69,
                                    "subsystem": "TGB",
                                    "serial": 84480,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 97.12,
                                    "subsystem": "TGB",
                                    "serial": 23337,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 93.78,
                                    "subsystem": "TGB",
                                    "serial": 23372,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 11496000,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 7358,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 95.67,
                                    "subsystem": "IGB",
                                    "serial": 22962,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.66,
                                    "subsystem": "IGB",
                                    "serial": 25730,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 95.58,
                                    "subsystem": "IGB",
                                    "serial": 82875,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 98.66,
                                    "subsystem": "IGB",
                                    "serial": 38438,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 91.06,
                                    "subsystem": "IGB",
                                    "serial": 75916,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 91.31,
                                    "subsystem": "IGB",
                                    "serial": 68150,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 11496000,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 6750,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 91845,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 73559,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 25668,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 11496000,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 30544,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 11496000,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 4,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 15278400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "09-35063",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 9185,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 96.08990070078684,
                                    "subsystem": "TGB",
                                    "serial": 48190,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 97.84,
                                    "subsystem": "TGB",
                                    "serial": 92872,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 98.34899643640013,
                                    "subsystem": "TGB",
                                    "serial": 91194,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 96.89,
                                    "subsystem": "TGB",
                                    "serial": 10077,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 93.48,
                                    "subsystem": "TGB",
                                    "serial": 83239,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.41,
                                    "subsystem": "TGB",
                                    "serial": 15129,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 3081600,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 2088,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 95.09,
                                    "subsystem": "IGB",
                                    "serial": 35373,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.71,
                                    "subsystem": "IGB",
                                    "serial": 87558,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 97.16,
                                    "subsystem": "IGB",
                                    "serial": 65148,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 92.31,
                                    "subsystem": "IGB",
                                    "serial": 21158,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 90.4,
                                    "subsystem": "IGB",
                                    "serial": 41186,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.42,
                                    "subsystem": "IGB",
                                    "serial": 35403,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 3081600,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 2249,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 96849,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 28527,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 26693,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 3081600,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 98396,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 3081600,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 5,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 16081200,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "07-86914",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5612,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.29,
                                    "subsystem": "TGB",
                                    "serial": 71320,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 92.51,
                                    "subsystem": "TGB",
                                    "serial": 87085,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92.71,
                                    "subsystem": "TGB",
                                    "serial": 99404,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 91.41,
                                    "subsystem": "TGB",
                                    "serial": 76719,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 98.77,
                                    "subsystem": "TGB",
                                    "serial": 16829,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 98.42,
                                    "subsystem": "TGB",
                                    "serial": 66589,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 12589200,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 8782,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 94.5,
                                    "subsystem": "IGB",
                                    "serial": 10400,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.01,
                                    "subsystem": "IGB",
                                    "serial": 52841,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.68,
                                    "subsystem": "IGB",
                                    "serial": 58711,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 93.24,
                                    "subsystem": "IGB",
                                    "serial": 52843,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.3,
                                    "subsystem": "IGB",
                                    "serial": 65622,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 94.07,
                                    "subsystem": "IGB",
                                    "serial": 72990,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 12589200,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 7844,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 26313,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 17678,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 40560,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 12589200,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 66279,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 12589200,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 6,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 15112800,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "12-78378",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 6050,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 95.8442371505301,
                                    "subsystem": "TGB",
                                    "serial": 80897,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 93.55,
                                    "subsystem": "TGB",
                                    "serial": 72924,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 94.78778115070807,
                                    "subsystem": "TGB",
                                    "serial": 57210,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 95.97,
                                    "subsystem": "TGB",
                                    "serial": 92623,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 94.99,
                                    "subsystem": "TGB",
                                    "serial": 77317,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 94.4,
                                    "subsystem": "TGB",
                                    "serial": 73730,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 4107600,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 1064,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 93.26,
                                    "subsystem": "IGB",
                                    "serial": 20604,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.02,
                                    "subsystem": "IGB",
                                    "serial": 43066,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.7,
                                    "subsystem": "IGB",
                                    "serial": 11896,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 91.14,
                                    "subsystem": "IGB",
                                    "serial": 27805,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.69,
                                    "subsystem": "IGB",
                                    "serial": 70032,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 93.46,
                                    "subsystem": "IGB",
                                    "serial": 50720,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 4107600,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 8241,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 48007,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 76151,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 67559,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 4107600,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 35737,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 4107600,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 7,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 13759200,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "15-38607",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5000,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 92.22,
                                    "subsystem": "TGB",
                                    "serial": 53486,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 99.47,
                                    "subsystem": "TGB",
                                    "serial": 48636,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92.35,
                                    "subsystem": "TGB",
                                    "serial": 65485,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 90.77,
                                    "subsystem": "TGB",
                                    "serial": 24825,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 97.74,
                                    "subsystem": "TGB",
                                    "serial": 10880,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 91.45,
                                    "subsystem": "TGB",
                                    "serial": 64385,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 828000,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 9577,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 91.11,
                                    "subsystem": "IGB",
                                    "serial": 18435,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.26,
                                    "subsystem": "IGB",
                                    "serial": 88896,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.83,
                                    "subsystem": "IGB",
                                    "serial": 99222,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 95.93,
                                    "subsystem": "IGB",
                                    "serial": 91206,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.29,
                                    "subsystem": "IGB",
                                    "serial": 32978,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.76,
                                    "subsystem": "IGB",
                                    "serial": 31378,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 828000,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 4117,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 38994,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 51293,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 82136,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 828000,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 71343,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 828000,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 8,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 13874400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "08-12968",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 3879,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 91.67,
                                    "subsystem": "TGB",
                                    "serial": 83350,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 94.1,
                                    "subsystem": "TGB",
                                    "serial": 67467,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 94.56,
                                    "subsystem": "TGB",
                                    "serial": 69691,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 93.12,
                                    "subsystem": "TGB",
                                    "serial": 31498,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 95.1,
                                    "subsystem": "TGB",
                                    "serial": 48928,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.72,
                                    "subsystem": "TGB",
                                    "serial": 79895,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9356400,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 6626,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 90.11,
                                    "subsystem": "IGB",
                                    "serial": 47589,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.06,
                                    "subsystem": "IGB",
                                    "serial": 77724,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.26,
                                    "subsystem": "IGB",
                                    "serial": 18937,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 91.13,
                                    "subsystem": "IGB",
                                    "serial": 94988,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 91.19,
                                    "subsystem": "IGB",
                                    "serial": 89136,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 91.82,
                                    "subsystem": "IGB",
                                    "serial": 94614,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9356400,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 2760,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 76546,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 14189,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 30740,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 9356400,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 62228,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 9356400,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 9,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 14216400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "10-27174",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 1977,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 91.27,
                                    "subsystem": "TGB",
                                    "serial": 73307,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 99.58,
                                    "subsystem": "TGB",
                                    "serial": 18704,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 94.38,
                                    "subsystem": "TGB",
                                    "serial": 29920,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 94.94,
                                    "subsystem": "TGB",
                                    "serial": 84450,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 94.24,
                                    "subsystem": "TGB",
                                    "serial": 61464,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 98.97,
                                    "subsystem": "TGB",
                                    "serial": 52257,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 7246800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 7570,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 98.21,
                                    "subsystem": "IGB",
                                    "serial": 78869,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.15,
                                    "subsystem": "IGB",
                                    "serial": 77560,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.38,
                                    "subsystem": "IGB",
                                    "serial": 76478,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 92.44,
                                    "subsystem": "IGB",
                                    "serial": 21525,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 90.71,
                                    "subsystem": "IGB",
                                    "serial": 25474,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 93.34,
                                    "subsystem": "IGB",
                                    "serial": 69861,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 7246800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 2480,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 37020,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 84123,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 16838,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 41922,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 7246800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 10,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 16380000,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "04-68741",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5354,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.29,
                                    "subsystem": "TGB",
                                    "serial": 31166,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 84.38,
                                    "subsystem": "TGB",
                                    "serial": 86715,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 93.97,
                                    "subsystem": "TGB",
                                    "serial": 34903,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 90.74,
                                    "subsystem": "TGB",
                                    "serial": 37583,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 96.77,
                                    "subsystem": "TGB",
                                    "serial": 34924,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.27,
                                    "subsystem": "TGB",
                                    "serial": 21545,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 8474400,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 9910,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 99.01,
                                    "subsystem": "IGB",
                                    "serial": 95872,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.01,
                                    "subsystem": "IGB",
                                    "serial": 81213,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.66,
                                    "subsystem": "IGB",
                                    "serial": 55895,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 96.99,
                                    "subsystem": "IGB",
                                    "serial": 40178,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.47,
                                    "subsystem": "IGB",
                                    "serial": 32282,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.12,
                                    "subsystem": "IGB",
                                    "serial": 12340,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 8474400,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 6428,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 95026,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 98913,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 98075,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 8474400,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 76722,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 8474400,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 11,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 17046000,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "07-70851",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5920,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.66,
                                    "subsystem": "TGB",
                                    "serial": 30672,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 90.48,
                                    "subsystem": "TGB",
                                    "serial": 48231,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92.66,
                                    "subsystem": "TGB",
                                    "serial": 15799,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 96.48,
                                    "subsystem": "TGB",
                                    "serial": 52545,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 92.22,
                                    "subsystem": "TGB",
                                    "serial": 48044,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 91.14,
                                    "subsystem": "TGB",
                                    "serial": 84072,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 3880800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 3554,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 95.77,
                                    "subsystem": "IGB",
                                    "serial": 73651,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.15,
                                    "subsystem": "IGB",
                                    "serial": 61547,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 92.51,
                                    "subsystem": "IGB",
                                    "serial": 75369,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 96.53,
                                    "subsystem": "IGB",
                                    "serial": 12149,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.9,
                                    "subsystem": "IGB",
                                    "serial": 73382,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 98.7,
                                    "subsystem": "IGB",
                                    "serial": 93620,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 3880800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 3910,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 30962,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 94912,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 12015,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 3880800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 31086,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 3880800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 12,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 13773600,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "12-22494",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 3892,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 92.41,
                                    "subsystem": "TGB",
                                    "serial": 40894,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 90.25,
                                    "subsystem": "TGB",
                                    "serial": 83618,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 90.43,
                                    "subsystem": "TGB",
                                    "serial": 76944,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 92.67,
                                    "subsystem": "TGB",
                                    "serial": 35536,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 92.73,
                                    "subsystem": "TGB",
                                    "serial": 99755,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 94.73,
                                    "subsystem": "TGB",
                                    "serial": 81298,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9997200,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 5120,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 97.48,
                                    "subsystem": "IGB",
                                    "serial": 55975,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.83,
                                    "subsystem": "IGB",
                                    "serial": 14290,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.12,
                                    "subsystem": "IGB",
                                    "serial": 82739,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 93.03,
                                    "subsystem": "IGB",
                                    "serial": 70871,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 93.23,
                                    "subsystem": "IGB",
                                    "serial": 34495,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 91.92,
                                    "subsystem": "IGB",
                                    "serial": 25969,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9997200,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 1693,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 34982,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 52723,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 57134,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 9997200,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 27204,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 9997200,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 13,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 16956000,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "15-56347",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5787,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.15,
                                    "subsystem": "TGB",
                                    "serial": 34480,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 93.25,
                                    "subsystem": "TGB",
                                    "serial": 35333,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92.19,
                                    "subsystem": "TGB",
                                    "serial": 51706,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 95.61,
                                    "subsystem": "TGB",
                                    "serial": 63740,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 95.39,
                                    "subsystem": "TGB",
                                    "serial": 82165,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.61,
                                    "subsystem": "TGB",
                                    "serial": 56826,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 1940400,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 6598,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 93.23,
                                    "subsystem": "IGB",
                                    "serial": 32213,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.95,
                                    "subsystem": "IGB",
                                    "serial": 20871,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 92.83,
                                    "subsystem": "IGB",
                                    "serial": 84747,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 97.59,
                                    "subsystem": "IGB",
                                    "serial": 87629,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.61,
                                    "subsystem": "IGB",
                                    "serial": 11424,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.91,
                                    "subsystem": "IGB",
                                    "serial": 81569,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 1940400,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 7115,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 72097,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 17555,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 77359,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 1940400,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 27719,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 1940400,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 14,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 17820000,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "10-97989",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5725,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 96.90461583627545,
                                    "subsystem": "TGB",
                                    "serial": 99943,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 99.21,
                                    "subsystem": "TGB",
                                    "serial": 85770,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 98.21438606994946,
                                    "subsystem": "TGB",
                                    "serial": 77731,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 99.58,
                                    "subsystem": "TGB",
                                    "serial": 61378,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 97.39,
                                    "subsystem": "TGB",
                                    "serial": 99178,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 98.18,
                                    "subsystem": "TGB",
                                    "serial": 99663,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9784800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 2826,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 94.61,
                                    "subsystem": "IGB",
                                    "serial": 94559,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.78,
                                    "subsystem": "IGB",
                                    "serial": 93178,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.25,
                                    "subsystem": "IGB",
                                    "serial": 86675,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 92.02,
                                    "subsystem": "IGB",
                                    "serial": 71076,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.28,
                                    "subsystem": "IGB",
                                    "serial": 21986,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 91.48,
                                    "subsystem": "IGB",
                                    "serial": 24474,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9784800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 4837,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 54132,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 67156,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 32349,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 9784800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 64174,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 9784800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 15,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 17096400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "06-26145",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 6842,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 90.03,
                                    "subsystem": "TGB",
                                    "serial": 42185,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 94.12,
                                    "subsystem": "TGB",
                                    "serial": 53498,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92,
                                    "subsystem": "TGB",
                                    "serial": 22231,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 91.57,
                                    "subsystem": "TGB",
                                    "serial": 34165,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 90.29,
                                    "subsystem": "TGB",
                                    "serial": 71905,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.76,
                                    "subsystem": "TGB",
                                    "serial": 37689,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 2138400,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 6676,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 95.56,
                                    "subsystem": "IGB",
                                    "serial": 64897,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.49,
                                    "subsystem": "IGB",
                                    "serial": 25498,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.99,
                                    "subsystem": "IGB",
                                    "serial": 80030,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 96.34,
                                    "subsystem": "IGB",
                                    "serial": 22265,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.42,
                                    "subsystem": "IGB",
                                    "serial": 17359,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.38,
                                    "subsystem": "IGB",
                                    "serial": 27704,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 2138400,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 2648,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 63828,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 90264,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 93360,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 2138400,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 37592,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 2138400,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 16,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 17125200,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "09-79473",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 7337,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.8776118585258,
                                    "subsystem": "TGB",
                                    "serial": 68969,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 93.05,
                                    "subsystem": "TGB",
                                    "serial": 43110,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 99.1724552588921,
                                    "subsystem": "TGB",
                                    "serial": 10402,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 96.96,
                                    "subsystem": "TGB",
                                    "serial": 33258,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 98.18,
                                    "subsystem": "TGB",
                                    "serial": 43851,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.81,
                                    "subsystem": "TGB",
                                    "serial": 73430,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9838800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 2665,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 95.87,
                                    "subsystem": "IGB",
                                    "serial": 36406,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.91,
                                    "subsystem": "IGB",
                                    "serial": 93216,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 95.76,
                                    "subsystem": "IGB",
                                    "serial": 81889,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 98.3,
                                    "subsystem": "IGB",
                                    "serial": 85623,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.27,
                                    "subsystem": "IGB",
                                    "serial": 93163,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.99,
                                    "subsystem": "IGB",
                                    "serial": 58206,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9838800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 1192,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 93873,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 39033,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 40018,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 9838800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 93586,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 9838800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 17,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 16232400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "09-94444",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 1408,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 92.02,
                                    "subsystem": "TGB",
                                    "serial": 93248,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 96.99,
                                    "subsystem": "TGB",
                                    "serial": 26263,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 91.53,
                                    "subsystem": "TGB",
                                    "serial": 94573,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 95.28,
                                    "subsystem": "TGB",
                                    "serial": 94370,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 94.23,
                                    "subsystem": "TGB",
                                    "serial": 29802,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.86,
                                    "subsystem": "TGB",
                                    "serial": 32056,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 7398000,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 7293,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 92.57,
                                    "subsystem": "IGB",
                                    "serial": 84968,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.38,
                                    "subsystem": "IGB",
                                    "serial": 76405,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.57,
                                    "subsystem": "IGB",
                                    "serial": 68416,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 94.72,
                                    "subsystem": "IGB",
                                    "serial": 92074,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 94.64,
                                    "subsystem": "IGB",
                                    "serial": 38580,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 91.65,
                                    "subsystem": "IGB",
                                    "serial": 68722,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 7398000,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 5954,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 18314,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 47952,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 32613,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 7398000,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 51398,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 7398000,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 18,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 14414400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "13-46097",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 1206,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.8,
                                    "subsystem": "TGB",
                                    "serial": 68313,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 97.41,
                                    "subsystem": "TGB",
                                    "serial": 38856,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 94.15,
                                    "subsystem": "TGB",
                                    "serial": 43468,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 98.9,
                                    "subsystem": "TGB",
                                    "serial": 42742,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 95.93,
                                    "subsystem": "TGB",
                                    "serial": 19362,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.55,
                                    "subsystem": "TGB",
                                    "serial": 83130,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 8481600,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 7155,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 93.17,
                                    "subsystem": "IGB",
                                    "serial": 61149,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.92,
                                    "subsystem": "IGB",
                                    "serial": 61679,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 97.05,
                                    "subsystem": "IGB",
                                    "serial": 18738,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 93.1,
                                    "subsystem": "IGB",
                                    "serial": 52969,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 93.84,
                                    "subsystem": "IGB",
                                    "serial": 33433,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.92,
                                    "subsystem": "IGB",
                                    "serial": 45654,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 8481600,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 3986,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 36652,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 83488,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 74019,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 8481600,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 14869,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 8481600,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 19,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 13629600,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "11-42506",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 8734,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.54,
                                    "subsystem": "TGB",
                                    "serial": 16401,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 95.65,
                                    "subsystem": "TGB",
                                    "serial": 88062,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 93.63,
                                    "subsystem": "TGB",
                                    "serial": 42476,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 95.82,
                                    "subsystem": "TGB",
                                    "serial": 78928,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 90.06,
                                    "subsystem": "TGB",
                                    "serial": 24480,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.51,
                                    "subsystem": "TGB",
                                    "serial": 18861,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9262800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 9569,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 91.97,
                                    "subsystem": "IGB",
                                    "serial": 18646,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.36,
                                    "subsystem": "IGB",
                                    "serial": 32389,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 96.62,
                                    "subsystem": "IGB",
                                    "serial": 13889,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 95.48,
                                    "subsystem": "IGB",
                                    "serial": 52079,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.71,
                                    "subsystem": "IGB",
                                    "serial": 49860,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.83,
                                    "subsystem": "IGB",
                                    "serial": 12286,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9262800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 9163,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 53384,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 52049,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 66053,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 9262800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 17573,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 9262800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 20,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 15300000,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "04-40121",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 4767,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.1,
                                    "subsystem": "TGB",
                                    "serial": 19267,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 95.23,
                                    "subsystem": "TGB",
                                    "serial": 97579,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 90.18,
                                    "subsystem": "TGB",
                                    "serial": 41377,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 96.98,
                                    "subsystem": "TGB",
                                    "serial": 44924,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 90.76,
                                    "subsystem": "TGB",
                                    "serial": 33250,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 94.79,
                                    "subsystem": "TGB",
                                    "serial": 34740,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 1666800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 2451,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 99.75,
                                    "subsystem": "IGB",
                                    "serial": 27838,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.07,
                                    "subsystem": "IGB",
                                    "serial": 51528,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 91.73,
                                    "subsystem": "IGB",
                                    "serial": 87699,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 98.01,
                                    "subsystem": "IGB",
                                    "serial": 49014,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 94.59,
                                    "subsystem": "IGB",
                                    "serial": 53592,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.29,
                                    "subsystem": "IGB",
                                    "serial": 27016,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 1666800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 5758,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 16332,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 62227,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 40121,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 1666800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 62817,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 1666800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 21,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 15206400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "12-83216",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5499,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 91.97,
                                    "subsystem": "TGB",
                                    "serial": 65092,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 97.86,
                                    "subsystem": "TGB",
                                    "serial": 89184,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92.97,
                                    "subsystem": "TGB",
                                    "serial": 54740,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 95.95,
                                    "subsystem": "TGB",
                                    "serial": 74687,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 93.25,
                                    "subsystem": "TGB",
                                    "serial": 92908,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 98.52,
                                    "subsystem": "TGB",
                                    "serial": 33637,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 5497200,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 3038,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 96.72,
                                    "subsystem": "IGB",
                                    "serial": 26912,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.51,
                                    "subsystem": "IGB",
                                    "serial": 14254,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.04,
                                    "subsystem": "IGB",
                                    "serial": 29210,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 97.77,
                                    "subsystem": "IGB",
                                    "serial": 54821,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 90.87,
                                    "subsystem": "IGB",
                                    "serial": 43483,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.64,
                                    "subsystem": "IGB",
                                    "serial": 43793,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 5497200,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 5133,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 71267,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 89475,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 52895,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 5497200,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 57245,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 5497200,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 22,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 14083200,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "13-18429",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 2599,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 92.82,
                                    "subsystem": "TGB",
                                    "serial": 37776,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 99.77,
                                    "subsystem": "TGB",
                                    "serial": 93093,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92.68,
                                    "subsystem": "TGB",
                                    "serial": 20205,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 90.18,
                                    "subsystem": "TGB",
                                    "serial": 32769,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 94.16,
                                    "subsystem": "TGB",
                                    "serial": 86670,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.46,
                                    "subsystem": "TGB",
                                    "serial": 70812,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 10846800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 6146,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 99.16,
                                    "subsystem": "IGB",
                                    "serial": 91378,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.45,
                                    "subsystem": "IGB",
                                    "serial": 68792,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 91.39,
                                    "subsystem": "IGB",
                                    "serial": 28047,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 97.26,
                                    "subsystem": "IGB",
                                    "serial": 16502,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.11,
                                    "subsystem": "IGB",
                                    "serial": 58773,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.62,
                                    "subsystem": "IGB",
                                    "serial": 78056,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 10846800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 9892,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 29528,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 16641,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 89478,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 10846800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 22672,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 10846800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 23,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 13298400,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "13-43665",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 6109,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 99.67832748991196,
                                    "subsystem": "TGB",
                                    "serial": 51857,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 95.28,
                                    "subsystem": "TGB",
                                    "serial": 58877,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 93.05,
                                    "subsystem": "TGB",
                                    "serial": 39322,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 98.94,
                                    "subsystem": "TGB",
                                    "serial": 77590,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 99,
                                    "subsystem": "TGB",
                                    "serial": 53899,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.92,
                                    "subsystem": "TGB",
                                    "serial": 24868,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 3888000,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 1916,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 96.84,
                                    "subsystem": "IGB",
                                    "serial": 98280,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.96,
                                    "subsystem": "IGB",
                                    "serial": 85178,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 97.95,
                                    "subsystem": "IGB",
                                    "serial": 96005,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 94.8,
                                    "subsystem": "IGB",
                                    "serial": 36985,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 97.29,
                                    "subsystem": "IGB",
                                    "serial": 53437,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.68,
                                    "subsystem": "IGB",
                                    "serial": 87478,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 3888000,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 9892,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 44359,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 67998,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 81429,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 3888000,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 85972,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 3888000,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 24,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 13629600,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "07-62406",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5611,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.53753499157382,
                                    "subsystem": "TGB",
                                    "serial": 99306,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 96.66,
                                    "subsystem": "TGB",
                                    "serial": 78463,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 92.60940395505139,
                                    "subsystem": "TGB",
                                    "serial": 54458,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 92.24,
                                    "subsystem": "TGB",
                                    "serial": 12316,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 94.55,
                                    "subsystem": "TGB",
                                    "serial": 90954,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 93.75,
                                    "subsystem": "TGB",
                                    "serial": 16387,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 13222800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 3899,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 94.74,
                                    "subsystem": "IGB",
                                    "serial": 20670,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.07,
                                    "subsystem": "IGB",
                                    "serial": 83494,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.49,
                                    "subsystem": "IGB",
                                    "serial": 66267,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 95.15,
                                    "subsystem": "IGB",
                                    "serial": 81441,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 94.76,
                                    "subsystem": "IGB",
                                    "serial": 75710,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.11,
                                    "subsystem": "IGB",
                                    "serial": 31102,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 13222800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 9241,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 95422,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 61207,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 16375,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 13222800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 69783,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 13222800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 25,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 16441200,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "13-40243",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 1443,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 91.79167108546578,
                                    "subsystem": "TGB",
                                    "serial": 56393,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 96.5,
                                    "subsystem": "TGB",
                                    "serial": 75002,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 90.82,
                                    "subsystem": "TGB",
                                    "serial": 82284,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 94.76,
                                    "subsystem": "TGB",
                                    "serial": 90701,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 95.64,
                                    "subsystem": "TGB",
                                    "serial": 57662,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.17,
                                    "subsystem": "TGB",
                                    "serial": 37799,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 4845600,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 4845,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 99.65,
                                    "subsystem": "IGB",
                                    "serial": 83940,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.92,
                                    "subsystem": "IGB",
                                    "serial": 80269,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 97.43,
                                    "subsystem": "IGB",
                                    "serial": 26958,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 97,
                                    "subsystem": "IGB",
                                    "serial": 11395,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.37,
                                    "subsystem": "IGB",
                                    "serial": 40584,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.18,
                                    "subsystem": "IGB",
                                    "serial": 20212,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 4845600,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 7330,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 76769,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 59646,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 41746,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 4845600,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 66278,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 4845600,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 26,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 13903200,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "06-38378",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 8931,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 97.24817221667145,
                                    "subsystem": "TGB",
                                    "serial": 58954,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 95.77,
                                    "subsystem": "TGB",
                                    "serial": 75010,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 97.88013745636486,
                                    "subsystem": "TGB",
                                    "serial": 83678,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 94.36,
                                    "subsystem": "TGB",
                                    "serial": 29737,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 97.98,
                                    "subsystem": "TGB",
                                    "serial": 29158,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.11,
                                    "subsystem": "TGB",
                                    "serial": 61196,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9666000,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 9376,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 91.18,
                                    "subsystem": "IGB",
                                    "serial": 90959,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 96.37,
                                    "subsystem": "IGB",
                                    "serial": 68975,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 94.51,
                                    "subsystem": "IGB",
                                    "serial": 90456,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 90.21,
                                    "subsystem": "IGB",
                                    "serial": 20194,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 99.07,
                                    "subsystem": "IGB",
                                    "serial": 31891,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 93.58,
                                    "subsystem": "IGB",
                                    "serial": 29375,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9666000,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 2856,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 52714,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 41391,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 70408,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 9666000,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 21300,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 9666000,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 27,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 13150800,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "15-32166",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 4496,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 92.61,
                                    "subsystem": "TGB",
                                    "serial": 86109,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 92.87,
                                    "subsystem": "TGB",
                                    "serial": 77452,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 93.48,
                                    "subsystem": "TGB",
                                    "serial": 20394,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 97.12,
                                    "subsystem": "TGB",
                                    "serial": 53501,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 90.32,
                                    "subsystem": "TGB",
                                    "serial": 95652,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 94.3,
                                    "subsystem": "TGB",
                                    "serial": 65394,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 3024000,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 5485,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 92.61,
                                    "subsystem": "IGB",
                                    "serial": 59194,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.6,
                                    "subsystem": "IGB",
                                    "serial": 83851,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 92.99,
                                    "subsystem": "IGB",
                                    "serial": 14417,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 91.03,
                                    "subsystem": "IGB",
                                    "serial": 24606,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 90.42,
                                    "subsystem": "IGB",
                                    "serial": 16957,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.64,
                                    "subsystem": "IGB",
                                    "serial": 96675,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 3024000,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 2639,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 79394,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 51546,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 18368,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 3024000,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 44260,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 3024000,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 28,
            "base": {
                "id": 1,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "A/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 17560800,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "06-52493",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 3295,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.23,
                                    "subsystem": "TGB",
                                    "serial": 66114,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 93.29,
                                    "subsystem": "TGB",
                                    "serial": 46504,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 84.76,
                                    "subsystem": "TGB",
                                    "serial": 60261,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 95.48,
                                    "subsystem": "TGB",
                                    "serial": 75972,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 91.48,
                                    "subsystem": "TGB",
                                    "serial": 32322,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 94.97,
                                    "subsystem": "TGB",
                                    "serial": 81894,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9712800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 1771,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 86.08,
                                    "subsystem": "IGB",
                                    "serial": 86541,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 98.95,
                                    "subsystem": "IGB",
                                    "serial": 32226,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 91.15,
                                    "subsystem": "IGB",
                                    "serial": 84487,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 91,
                                    "subsystem": "IGB",
                                    "serial": 28510,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 93.99,
                                    "subsystem": "IGB",
                                    "serial": 21639,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.98,
                                    "subsystem": "IGB",
                                    "serial": 83365,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 9712800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 3898,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 56032,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 14123,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 44005,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 9712800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 82642,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 9712800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 29,
            "base": {
                "id": 2,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "B/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 16765200,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "15-51992",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 5166,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 94.79,
                                    "subsystem": "TGB",
                                    "serial": 99792,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 93.34,
                                    "subsystem": "TGB",
                                    "serial": 73859,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 86.32,
                                    "subsystem": "TGB",
                                    "serial": 89739,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 96.92,
                                    "subsystem": "TGB",
                                    "serial": 67416,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 97.82,
                                    "subsystem": "TGB",
                                    "serial": 28238,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 92.86,
                                    "subsystem": "TGB",
                                    "serial": 36666,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 7246800,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 1630,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 88.93,
                                    "subsystem": "IGB",
                                    "serial": 84139,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.09,
                                    "subsystem": "IGB",
                                    "serial": 99302,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.4,
                                    "subsystem": "IGB",
                                    "serial": 25073,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 98.73,
                                    "subsystem": "IGB",
                                    "serial": 46865,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 98.62,
                                    "subsystem": "IGB",
                                    "serial": 99008,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 99.75,
                                    "subsystem": "IGB",
                                    "serial": 31894,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 7246800,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 2700,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 62779,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 50382,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 54781,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 7246800,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 29379,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 7246800,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        },
        {
            "id": 30,
            "base": {
                "id": 3,
                "airfield": "Simmons Airfield",
                "battalion": "2-82 ASSLT BN",
                "brigade": "82D Combat AVN BDE",
                "division": "82nd Airborne Division",
                "fleetSize": "10",
                "icao": "KFBG",
                "post": "Fort Bragg",
                "state": "Virginia",
                "unit": "C/2-28 ASSLT"
            },
            "fleet": {
                "id": 1,
                "name": "Sikorsky UH-60 Black Hawk",
                "image": "uh60-darkblue.png"
            },
            "flightTime": 16452000,
            "image": "uh60side",
            "missions": [],
            "model": "uh60m",
            "serial": "11-62293",
            "systems": [
                {
                    "id": 1,
                    "image": "tgb_housing_assembly_v6.png",
                    "name": "Rear Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 1,
                            "name": "Tail Rotor Gearbox",
                            "serial": 3548,
                            "partNum": "70358-06600-053",
                            "components": [
                                {
                                    "id": 1,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Output Gear",
                                    "partNum": "70358-06603-102",
                                    "score": 95.82,
                                    "subsystem": "TGB",
                                    "serial": 27843,
                                    "image": "tgb_output_gear"
                                },
                                {
                                    "id": 4,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3314",
                                    "score": 90.62,
                                    "subsystem": "TGB",
                                    "serial": 82748,
                                    "image": "tgb_output_assy_output_bearing"
                                },
                                {
                                    "id": 5,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3353",
                                    "score": 86.21,
                                    "subsystem": "TGB",
                                    "serial": 25199,
                                    "image": "tgb_output_assy_input_bearing"
                                },
                                {
                                    "id": 9,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Input Pinion",
                                    "partNum": "70358-06619-101",
                                    "score": 98.06,
                                    "subsystem": "TGB",
                                    "serial": 92882,
                                    "image": "tgb_input_pinion"
                                },
                                {
                                    "id": 12,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3266",
                                    "score": 97.25,
                                    "subsystem": "TGB",
                                    "serial": 23150,
                                    "image": "tgb_input_assy_output_bearing"
                                },
                                {
                                    "id": 13,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 95.56,
                                    "subsystem": "TGB",
                                    "serial": 30563,
                                    "image": "tgb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 6908400,
                            "tbo": 5800,
                            "image": "tgb_assembly_w_components_v6"
                        }
                    ]
                },
                {
                    "id": 2,
                    "image": "igb_housing_assembly_v3.png",
                    "name": "Intermediate Drivetrain Assembly",
                    "subsystems": [
                        {
                            "id": 2,
                            "image": "igb_assembly_w_components_v3",
                            "name": "Intermediate Gearbox",
                            "serial": 8737,
                            "partNum": "70357-06300-044",
                            "components": [
                                {
                                    "id": 2,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Output Gear",
                                    "partNum": "70357-06315-101",
                                    "score": 83.78,
                                    "subsystem": "IGB",
                                    "serial": 25630,
                                    "image": "igb_output_gear"
                                },
                                {
                                    "id": 6,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Output Assembly Output Bearing",
                                    "partNum": "SB-3217",
                                    "score": 90.22,
                                    "subsystem": "IGB",
                                    "serial": 88369,
                                    "image": "igb_output_assy_output_bearing"
                                },
                                {
                                    "id": 7,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Output Assembly Input Bearing",
                                    "partNum": "SB-3265",
                                    "score": 97.86,
                                    "subsystem": "IGB",
                                    "serial": 75947,
                                    "image": "igb_output_assy_input_bearing"
                                },
                                {
                                    "id": 10,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Input Pinion",
                                    "partNum": "70357-06314-101",
                                    "score": 98.6,
                                    "subsystem": "IGB",
                                    "serial": 55309,
                                    "image": "igb_input_pinion"
                                },
                                {
                                    "id": 14,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Input Assembly Output Bearing",
                                    "partNum": "SB-3265",
                                    "score": 90.7,
                                    "subsystem": "IGB",
                                    "serial": 46893,
                                    "image": "igb_input_assy_output_bearing"
                                },
                                {
                                    "id": 15,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Input Assembly Input Bearing",
                                    "partNum": "SB-3217",
                                    "score": 97.19,
                                    "subsystem": "IGB",
                                    "serial": 64781,
                                    "image": "igb_input_assy_input_bearing"
                                }
                            ],
                            "flightTime": 6908400,
                            "tbo": 7000
                        }
                    ]
                },
                {
                    "id": 3,
                    "name": "Main Rotor Assembly",
                    "subsystems": [
                        {
                            "id": 3,
                            "name": "Main Gearbox",
                            "serial": 5512,
                            "partNum": "70358-06600-065",
                            "components": [
                                {
                                    "id": 3,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Output Gear",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 69267,
                                    "image": "mgb_output_gear"
                                },
                                {
                                    "id": 8,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Output Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 21262,
                                    "image": "mgb_output_bearing"
                                },
                                {
                                    "id": 11,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Input Pinion",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 97001,
                                    "image": "mgb_input_pinion"
                                },
                                {
                                    "id": 16,
                                    "failure_probability": 0,
                                    "flightTime": 6908400,
                                    "name": "Input Bearing",
                                    "partNum": "12-0345-M",
                                    "score": 100,
                                    "subsystem": "MGB",
                                    "serial": 58719,
                                    "image": "mgb_input_bearing"
                                }
                            ],
                            "flightTime": 6908400,
                            "tbo": 7000
                        }
                    ]
                }
            ]
        }
    ]

    private REST_API_SERVER = this.envService.apiUrl;
    private TOKEN = JSON.parse(localStorage.getItem('currentUser'));

    currentSystem: any;
    currentVehicle: any;

    constructor(
        private envService: EnvService,
        private http: HttpClient,
        private messageService: MessageService,
    ) { }

    setSystem(id) {
        this.currentSystem = id;
    }

    setVehicle(id) {
        this.currentVehicle = id;
    }


    getHardAll() {
        return this.VEHICLES
    }

    add(data) {
        let headers = new HttpHeaders();
        data = JSON.parse(data);
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.post(this.REST_API_SERVER + '/order/', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getAll() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/order/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getArchived() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/archived/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getByFleet(id) {
        let vehicles = [];
        for (let vehicle of this.VEHICLES) {
            if (vehicle.fleet.id == id) {
                vehicles.push(vehicle);
            }
        }

        return vehicles
    }

    getByVehicle(id) {
        let vehicles = [];
        for (let vehicle of this.VEHICLES) {
            if (vehicle.id == id) {
                vehicles.push(vehicle);
            }
        }
        return vehicles
    }

    getVehiclesByPost(baseId: Number) {
        let vehicles = this.VEHICLES.filter((x) => {
            return (x.base.id == baseId ? true : false)
        })
        return vehicles
    }

    getFlagged() {
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/flagged/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    getSearch(data) {
        // Create headers
        let headers = new HttpHeaders();
        let search_string = ['?1'];
        if (data.hasOwnProperty('status')) {
            if (data['status'] == 'all') {
                //
            } else {
                search_string.push('&status=' + data['status']);
            }
        } else {
            search_string.push('&status=active');
        }
        if (data.hasOwnProperty('date')) {
            search_string.push('&date=' + data['date']);
        }
        if (data.hasOwnProperty('dateEnd')) {
            search_string.push('&date_end=' + data['dateEnd']);
        }
        if (data.hasOwnProperty('dateOrder')) {
            search_string.push('&date_order=' + data['dateOrder']);
        }
        if (data.hasOwnProperty('dateStart')) {
            search_string.push('&date_start=' + data['dateStart']);
        }
        if (data.hasOwnProperty('mln')) {
            search_string.push('&mln=' + data['mln']);
        }
        if (data.hasOwnProperty('product')) {
            search_string.push('&product=' + data['product']);
        }
        // TYPE
        if (data.hasOwnProperty('type')) {
            search_string.push('&type=' + data['type']);
        }
        let search = search_string.join("");
        console.log(search)
        return this.http.get(this.REST_API_SERVER + '/shipping/' + search, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    get(id) {

        for (let vehicle of this.VEHICLES) {
            if (vehicle.serial == id) {
                return vehicle;
            }
        }

        return 'ERROR'
        /*
        let headers = new HttpHeaders();
        return this.http.get(this.REST_API_SERVER + '/order/' + id,{
            headers: {
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.TOKEN.access,
            }
         });
         */
    }

    partial_update(data) {
        let headers = new HttpHeaders();
        let id = data['id'];
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.patch(this.REST_API_SERVER + '/order_partial/' + id + '/', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    update(id, data) {
        let headers = new HttpHeaders();
        data = JSON.parse(data);
        data['id_user'] = this.TOKEN.id;
        data = JSON.stringify(data);
        return this.http.put(this.REST_API_SERVER + '/order/' + id + '/', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.TOKEN.access,
            }
        });
    }

    sortActions(orders) {
        /*
            This will sort all of the actions returned and make them accessible for icon display etc
        */
        console.log(JSON.stringify(orders))
        if (orders.hasOwnProperty('addresses')) {
            orders.printed = 0;
            for (let action of orders.actions) {
                if (action.variety == 'print') {
                    orders.printed = orders.printed + 1;
                }
            }
        } else {
            for (let order of orders) {
                order.printed = 0;
                for (let action of order.actions) {
                    if (action.variety == 'print') {
                        order.printed = order.printed + 1;
                    }
                }
            }
        }

        return orders
    }
}   