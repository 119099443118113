import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service'

import { MessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class SegmentService {

    SEGMENTS = [
        {
            "id": 1,
            "altitude": "0",
            "airspeed": "VBR",
            "category": "Takeoff",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00008
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.0001
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00019
                }
            ],
            "flightTime": 10.5,
            "name": "Takeoff",
            "temperature": "86.5 °F"
        },
        {
            "id": 2,
            "altitude": "300' AHO",
            "airspeed": "180 KTAS",
            "category": "Cruise",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00013
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00009
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0
                }
            ],
            "flightTime": 27,
            "name": "H.A. Cruise",
            "temperature": "52.3 °F"
        },
        {
            "id": 3,
            "altitude": "-",
            "airspeed": "-",
            "category": "Land",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00006
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00018
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.0002
                }
            ],
            "flightTime": 10.5,
            "name": "Landing",
            "temperature": "32.6 °F"
        },
        {
            "id": 4,
            "altitude": "-",
            "airspeed": "-",
            "category": "Unload",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00014
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00012
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00012
                }
            ],
            "flightTime": 22,
            "name": "Unload",
            "temperature": "13.7 °F"
        },
        {
            "id": 5,
            "altitude": "-",
            "airspeed": "-",
            "category": "Load",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00016
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00002
                }
            ],
            "flightTime": 25,
            "name": "Load",
            "temperature": "97.8 °F"
        },
        {
            "id": 6,
            "altitude": "200' AHO",
            "airspeed": "-",
            "category": "Takeoff",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00005
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00004
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00012
                }
            ],
            "flightTime": 0.5,
            "name": "T/O TAA to 200’ AHO",
            "temperature": "63.4 °F"
        },
        {
            "id": 7,
            "altitude": "-",
            "airspeed": "225 KTAS",
            "category": "Cruise",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00004
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00006
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00004
                }
            ],
            "flightTime": 21.3,
            "name": "Cruise at 225 KTAS (1)",
            "temperature": "63.4 °F"
        },
        {
            "id": 8,
            "altitude": "-",
            "airspeed": "-",
            "category": "Land",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00001
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00012
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00014
                }
            ],
            "flightTime": 1,
            "name": "Descend to land PZ",
            "temperature": "63.4 °F"
        },
        {
            "id": 9,
            "altitude": "-",
            "airspeed": "-",
            "category": "Ground Idle",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00008
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00001
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00016
                }
            ],
            "flightTime": 5,
            "name": "PZ Load (ground idle)",
            "temperature": "63.4 °F"
        },
        {
            "id": 10,
            "altitude": "80' AHO",
            "airspeed": "-",
            "category": "Takeoff",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00014
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00014
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00006
                }
            ],
            "flightTime": 0.5,
            "name": "T/O PZ to 80’ AHO",
            "temperature": "63.4 °F"
        },
        {
            "id": 11,
            "altitude": "-",
            "airspeed": "225 KTAS",
            "category": "Cruise",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.0002
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00012
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00002
                }
            ],
            "flightTime": 29.3,
            "name": "Cruise at 225 KTAS (2)",
            "temperature": "63.4 °F"
        },
        {
            "id": 12,
            "altitude": "-",
            "airspeed": "-",
            "category": "Land",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00001
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00003
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00011
                }
            ],
            "flightTime": 29.3,
            "name": "Descend to land LZ",
            "temperature": "63.4 °F"
        },
        {
            "id": 13,
            "altitude": "-",
            "airspeed": "-",
            "category": "Ground Idle",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00009
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00015
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00002
                }
            ],
            "flightTime": 1,
            "name": "Unload at LZ (ground idle)",
            "temperature": "63.4 °F"
        },
        {
            "id": 14,
            "altitude": "80' AHO",
            "airspeed": "-",
            "category": "Takeoff",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00002
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00013
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00014
                }
            ],
            "flightTime": 29.3,
            "name": "T/O LZ to 80’ AHO",
            "temperature": "63.4 °F"
        },
        {
            "id": 15,
            "altitude": "-",
            "airspeed": "VBR",
            "category": "Cruise",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00004
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00019
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00013
                }
            ],
            "flightTime": 10,
            "name": "Cruise at VBR (1)",
            "temperature": "63.4 °F"
        },
        {
            "id": 16,
            "altitude": "200' AHO",
            "airspeed": "-",
            "category": "Ascend",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00006
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00011
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00017
                }
            ],
            "flightTime": 5,
            "name": "Climb vic PZ to 200’ AHO 1",
            "temperature": "63.4 °F"
        },
        {
            "id": 17,
            "altitude": "-",
            "airspeed": "VBR",
            "category": "Cruise",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.00002
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00007
                }
            ],
            "flightTime": 15,
            "name": "Cruise at VBR (2)",
            "temperature": "63.4 °F"
        },
        {
            "id": 18,
            "airspeed": "TAA",
            "category": "Land",
            "components": [
                {
                    "id": 1,
                    "name": "Rear Primary Gear",
                    "score": 0.00019
                },
                {
                    "id": 2,
                    "name": "Intermediate Primary Gear",
                    "score": 0.0002
                },
                {
                    "id": 3,
                    "name": "Main Primary Gear",
                    "score": 0.00002
                }
            ],
            "flightTime": 1,
            "name": "Descend to land TAA (no hover)",
            "temperature": "63.4 °F"
        }
    ]

    private REST_API_SERVER = this.envService.apiUrl;
    private TOKEN = JSON.parse(localStorage.getItem('currentUser'));

    currentSegment: any;

    constructor(
        private envService: EnvService,
        private http: HttpClient,
        private messageService: MessageService,
    ) { }

    setSegment(id) {
        this.currentSegment = id;
    }

    getBySegment(segmentId) {
        let segment = this.SEGMENTS.filter(x => x.id === segmentId)
        return segment[0]
    }

    getCurrentSegment() {
        for (let segment of this.SEGMENTS) {
            if (segment.id == this.currentSegment) {
                return segment
            }
        }
    }

    getHardAll() {
        return this.SEGMENTS
    }

    getComponentsById(segmentId: Number) {
        const segment = this.SEGMENTS.filter(x => {
            return x.id === segmentId
        })
        return segment[0]['components']
    }
}   