import { Injectable } from '@angular/core';
import { MixService } from './mix.service';
import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class SimulationService {

    private currentAircraft: any;
    private degradationRates: any;
    private originalState: any;
    private previousSimulationState = [];
    private simulationState = [];
    private missionMix: any;
    private vehicles: any;

    constructor(
        private mixService: MixService,
        private settingsService: SettingsService,
    ) { }

    // New Simulation Strategy
    calculateNextState(systems, hours, intensity) {
        let rates = this.settingsService.getSetting('degradationRates')
        for (let sys of systems) {
            for (let sub of sys.subsystems) {
                for (let comp of sub.components) {
                    if (comp.id == 1 || comp.id == 5 || comp.id == 2) {
                        comp.score *= rates[comp.subsystem][intensity][hours]
                    } else {
                        comp.score = Math.max((comp.score * rates.minimum), 90);
                    }
                }
            }
        }
        return systems;
    }

    composeDegradationRates() {
        this.degradationRates = this.mixService.getTotalWearRate(this.missionMix.id);
    }

    getCurrentAircraft() {
        return this.currentAircraft
    }

    getState() {
        return this.simulationState;
    }

    resetSimulation() {
        this.simulationState = JSON.parse(JSON.stringify(this.originalState));
        this.currentAircraft = false;
        return this.simulationState
    }

    setOriginalState(state) {
        this.originalState = JSON.parse(JSON.stringify(state))
    }

    setAircraft(aircraft) {
        this.currentAircraft = aircraft;
    }

    runMinimalSimulation(hours, intensity) {
        for (let vehicle of this.simulationState) {
            let systemState = vehicle.systems
            vehicle.systems = this.calculateNextState(systemState, hours, intensity);
        }
        return this.simulationState;
    }

    setMissionMix(id) {
        this.missionMix = this.mixService.getByMix(id)
        this.composeDegradationRates()
    }

    setVehicles(initVehicles) {
        this.vehicles = initVehicles
        this.simulationState = this.vehicles
    }
}