import { style } from '@angular/animations';
import { JsonpClientBackend } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ComponentService, MaintenanceService, SimulationService, VehicleService } from './../../_services/'

@Component({
    selector: 'layer-component',
    templateUrl: './layer-component.component.html',
    styleUrls: ['./layer-component.component.scss'],
})
export class LayerComponentComponent implements OnInit {
    @Input() rear: any; // decorate the property with @Input()
    @Input() rearbearing: any; // decorate the property with @Input()

    aircraft: any;
    color: any;
    component: any;
    components: any;
    selectedComponent: any;
    system: any;
    subsystem1: any;
    subsystem2: any;

    constructor(
        public componentService: ComponentService,
        public maintenanceService: MaintenanceService,
        public simulationService: SimulationService,
        public vehicleService: VehicleService,
    ) { }

    ngOnInit() {
        this.aircraft = this.simulationService.getCurrentAircraft();
        if (!this.aircraft || (Object.keys(this.aircraft).length === 0 && this.aircraft.constructor === Object)) {
            this.aircraft = this.vehicleService.getByVehicle(this.vehicleService.currentVehicle)[0];
        }
        this.components = this.minComponent();
    }

    addJob(data) {
        let job = {
            "subsystem": data,
            "aircraft": this.vehicleService.currentVehicle,
            "note": "Replace the ",
        }
        this.maintenanceService.addJob(job);
        this.closeSubsystem();
    }

    closeSystem(animate = true) {
        // God forgive me...
        if (animate != false) {
            setTimeout(() => {
                let component = document.getElementById('component')
                component.style.animationPlayState = 'revert';
                component.style.animationName = 'placeholder';
                component.style.visibility = 'hidden';
                setTimeout(() => {
                    let subsystem = document.getElementById('subsystem')
                    subsystem.style.animationPlayState = 'revert';
                    subsystem.style.animationName = 'placeholder';
                    subsystem.style.visibility = 'hidden';
                    // let parentTable = document.getElementById('detailInfo');
                    // parentTable.style.visibility = 'visible';
                    setTimeout(() => {
                        let system = document.getElementById('system')
                        system.style.animationPlayState = 'revert';
                        system.style.animationName = 'placeholder';
                        system.style.visibility = 'hidden';
                    }, 225)
                }, 175)
            }, 50)
        } else {
            let component = document.getElementById('component')
            component.style.animationPlayState = 'revert';
            component.style.animationName = 'placeholder';
            component.style.visibility = 'hidden';

            let subsystem = document.getElementById('subsystem')
            subsystem.style.animationPlayState = 'revert';
            subsystem.style.animationName = 'placeholder';
            subsystem.style.visibility = 'hidden';

            // let parentTable = document.getElementById('detailInfo');
            // parentTable.style.visibility = 'visible';

            let system = document.getElementById('system')
            system.style.animationPlayState = 'revert';
            system.style.animationName = 'placeholder';
            system.style.visibility = 'hidden';
        }
    }

    closeSubsystem(animate = true) {
        // I'M SORRY IT LOOKS COOL
        if (animate != false) {
            setTimeout(() => {
                let component = document.getElementById('component')
                component.style.animationPlayState = 'revert';
                component.style.animationName = 'placeholder';
                component.style.visibility = 'hidden';
                setTimeout(() => {
                    let subsystem = document.getElementById('subsystem')
                    subsystem.style.animationPlayState = 'revert';
                    subsystem.style.animationName = 'placeholder';
                    subsystem.style.visibility = 'hidden';
                    // let parentTable = document.getElementById('detailInfo');
                    // parentTable.style.visibility = 'visible';
                }, 175)
            }, 50)
        } else {
            let subsystem = document.getElementById('subsystem')
            subsystem.style.animationPlayState = 'revert';
            subsystem.style.animationName = 'placeholder';
            subsystem.style.visibility = 'hidden';
            // let parentTable = document.getElementById('detailInfo');
            // parentTable.style.visibility = 'visible';
        }
    }

    closeComponent() {
        let component = document.getElementById('component')
        component.style.animationPlayState = 'revert';
        component.style.animationName = 'placeholder';
        component.style.visibility = 'hidden';
    }

    minComponent() {
        return this.componentService.getMinimumComponent(this.aircraft)
    }

    showSubSystem(sub) {
        let relComponents;
        if (sub.id == 1) {
            // Tail Rotor Gearbox
            relComponents = [1, 5]
        } else if (sub.id == 2) {
            // Intermediate Gear Box
            relComponents = [2, 6]
        }
        // Grab relevant component(s) 
        let relevantComponents = sub.components.filter((x) => (relComponents.includes(Number(x.id))) ? true : false)
        let gear = relevantComponents[0];
        let bearing = relevantComponents[1];

        // Set Colors
        this.subsystem1 = this.getColor(gear.score);
        this.subsystem2 = this.getColor(bearing.score);

        // Close any prior panes and open new.
        this.closeSubsystem(false);
        // let parentTable = document.getElementById('detailInfo');
        // parentTable.style.visibility = 'hidden';
        let subsystem = document.getElementById('subsystem')
        subsystem.style.visibility = 'visible';
        subsystem.style.animationDuration = '.5s';
        subsystem.style.animationName = 'example';
        subsystem.style.animationPlayState = 'running';
    }

    showComponent(subsystem, compId = 0) {
        if (!compId && subsystem.id == 1) {
            compId = 5;
        } else if (!compId && subsystem.id == 2) {
            compId = 2;
        }

        let componentList = subsystem.components
        this.selectedComponent = componentList.filter(x => x.id == compId)[0]
        let score = this.selectedComponent.score
        if (score >= 90) {
            this.color = 'G'
        } else if (score < 90 && score >= 80) {
            this.color = 'Y'
        } else if (score < 80) {
            this.color = 'R'
        }

        this.closeComponent();

        let component = document.getElementById('component')
        component.style.animationDuration = '.5s';
        component.style.animationName = 'example';
        component.style.animationPlayState = 'running';
        component.style.visibility = 'visible';
    }

    get currentSystem() {
        let find = this.vehicleService.currentSystem
        for (let system of this.aircraft.systems) {
            if (find == system.id) {
                this.system = system;
                return system
            }
        }
    }

    getColor(score) {
        if (score < 80) {
            return 'R';
        } else if (score >= 80 && score < 90) {
            return 'Y';
        } else if (score >= 90) {
            return 'G';
        }
    }
}
